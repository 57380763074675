import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../components/layout";
import { Block, Span, Img, Button } from "@andyfischer/react-elements";
import ExaminingArkanasas from '../../content/assets/research_paper_preview_images/examining_arkansas.png';
import AnalyzingEmotionalDiscourse from '../../content/assets/research_paper_preview_images/analyzing_emotional_discourse.png';
import HowAreEmotions from '../../content/assets/research_paper_preview_images/how_are_emotions.png';
import PolicyChange from '../../content/assets/research_paper_preview_images/policy_change.png';
import PolicyFeedbackGermany from '../../content/assets/research_paper_preview_images/policy_feedback_germany.png';
import PolicyFeedbackPreemption from '../../content/assets/research_paper_preview_images/policy_feedback_preemption.png';
import Rurality from '../../content/assets/research_paper_preview_images/rurality.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
    <link href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
    <style type="text/css">
      {`
.cormorant_garamond_300 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: normal;
}

.cormorant_garamond_600 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: normal;
}

.text_lg {
    font-size: 3rem;
}

.grid {
    display: grid;
}

.research_paper_list_grid {
    grid-template: auto / 1fr 1fr 1fr;
    grid-gap: 60px;
}

@media (max-width: 1000px) {
  .research_paper_list_grid {
    grid-template: auto / 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .research_paper_list_grid {
    grid-template: auto / 1fr;
  }
}

.paper_title {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}

.paper_authors {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
}

.paper_preview_image {
}

.credits {
}

.p_1 {
    padding: 0.25rem;
}

.p_2 {
    padding: 0.5rem;
}

.p_3 {
    padding: 0.75rem;
}

.p_4 {
    padding: 1rem;
}

.font_bold {
    font-weight: 700;
}

.full_text_button {
    margin-top: 0.5rem;
    font-size: 1.2rem;

    padding: 0.5rem 0.75rem;

}

`}
    </style>
    <Block cormorant_garamond_600 text_lg p_2 bold style={{
      marginBottom: "60px"
    }} mdxType="Block">
Selected Publications
    </Block>
    <Block grid research_paper_list_grid mdxType="Block">
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={ExaminingArkanasas} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "Examining emotional belief expressions of advocacy coalitions in Arkansas' gender identity politics"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
          <Span font_bold mdxType="Span">Allegra H Fullerton</Span>, Christopher M Weible. (2024). "Examining emotional belief expressions of advocacy coalitions in Arkansas' gender identity politics." Policy Studies Journal. https://doi.org/10.1111/psj.12531
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:_FxGoFyzp5QC">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={PolicyFeedbackPreemption} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "The policy feedback effects of preemption"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
Mallory E SoRelle, <Span font_bold mdxType="Span">Allegra H Fullerton</Span>. (2023). "The policy feedback effects of preemption." Policy Studies Journal. 
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:eQOLeE2rZwMC">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={AnalyzingEmotionalDiscourse} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "Analysing emotional discourse among allies and opponents in the news media"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
          <Span font_bold mdxType="Span">Fullerton, A.H.</Span>, Gabehart, K.M., Yordy, J. & Weible, C.M. (2023, forthcoming). “Analysing Emotional Discourse among Allies and Opponents in Newsmedia.” Emotions and Society. Accepted as part of a special issue. https://doi.org/10.1332/263169021X16893162013622
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:Y0pCki6q_DkC">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={Rurality} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "Rurality, Resilience, & Identity: A Soft Systems Methodology Approach to Understanding Self-Reported Issues in Rural America"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
Kayla M. Gabehart, <Span font_bold mdxType="Span">Allegra H. Fullerton</Span>, Kristin Olofsson. (2024). "Rurality, Resilience, & Identity: A Soft Systems Methodology
Approach to Understanding Self-Reported Issues in Rural America". Online Journal of Rural Research & Policy.
        </Block>
        <a target="_blank" href="https://newprairiepress.org/ojrrp/vol19/iss1/1/">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={PolicyFeedbackGermany} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "Policy feedback and the enforcement of international wildlife treaties in Germany"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
Kayla M Gabehart, <Span font_bold mdxType="Span">Allegra H Fullerton</Span>, Christoph H Stefes. (2024). "Policy feedback and the enforcement of international wildlife treaties in Germany." European Policy Analysis.
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:YsMSGLbcyi4C">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={HowAreEmotions} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "How are emotions and beliefs expressed in legislative testimonies? An advocacy coalition approach"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
Gabehart, K.M., <Span font_bold mdxType="Span">Fullerton, A.H.</Span>, Crawford, A.M., and Weible C.M. (2023). “How are emotions and beliefs expressed in legislative testimonies? An advocacy coalition approach.” Review of Policy Research. https://doi.org/10.1111/ropr.12562
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:Tyk-4Ss8FVUC">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
      <Block mdxType="Block">
        <Block mdxType="Block">
  <Img src={PolicyChange} mdxType="Img" />
        </Block>
        <Block paper_title mdxType="Block">
    "Policy Change"
        </Block>
        <Block paper_authors p_1 mdxType="Block">
          <Span font_bold mdxType="Span">Allegra H Fullerton</Span>, Christopher M Weible. (2023). "Policy Change". Encyclopedia of Public Policy.
        </Block>
        <a target="_blank" href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=JCxgp08AAAAJ&sortby=pubdate&citation_for_view=JCxgp08AAAAJ:WF5omc3nYNoC">
          <Button full_text_button mdxType="Button">Full Text</Button>
        </a>
      </Block>
    </Block>
    <Block cormorant_garamond_600 text_lg p_2 style={{
      marginTop: '50px'
    }} mdxType="Block">
Forthcoming
    </Block>
    <Block paper_title p_1 mdxType="Block">
      <Span font_bold mdxType="Span">Fullerton, A.H.</Span>, Gabehart, K.M., & Weible, C.M. (2023, forthcoming). “Emotions and Advocacy Coalitions in a U.S. Pipeline Citing Conflict.” International Public Policy Review. Under Review.
    </Block>
    <Block paper_title p_1 mdxType="Block">
Weible, Christopher M., <Span font_bold mdxType="Span">Allegra H. Fullerton</Span>, Kayla M. Gabehart, Tanya Heikkila, Karin Ingold, Elizabeth Koebele, and Daniel Nohrstedt. (forthcoming). “Advancing the Study of Policy Change in the Advocacy Coalition Framework: Lessons from Oil and Gas 
Legislation in Colorado.” Policy Studies Journal. Under Review.
    </Block>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      